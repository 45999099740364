var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "__load_wallet__list"
  }, [_c('li', [_c('div', {
    staticClass: "__card_icon"
  }, [_vm.data.top_up_by ? _c('button', {
    staticClass: "__card_icon_icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/Arrow - Down.svg")
    }
  })]) : _vm._e(), _vm.data.bundle_by ? _c('button', {
    staticClass: "purchase__bundle__btn"
  }, [_c('img', {
    attrs: {
      "width": "10px",
      "height": "10px",
      "src": require("@/assets/Arrow - Up.svg"),
      "alt": ""
    }
  })]) : _vm._e()]), _c('div', {
    staticClass: "__card_wallet_load contains-load"
  }, [_c('p', {
    staticClass: "__top__text"
  }, [_vm._v(" " + _vm._s(_vm.data.top_up_by ? "Load Wallet" : "Bundle purchased") + " ")]), _c('p', {
    staticClass: "__date"
  }, [_vm._v(" " + _vm._s(_vm.moment(_vm.data.created_at).format("l")) + " ")])]), _c('div', {
    staticClass: "__card__amount contains-anount"
  }, [_c('p', {
    staticClass: "__amount contains-amount"
  }, [_vm._v(_vm._s(_vm.processAmount(_vm.data.amount)))])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };