import Storage from "../../../../services/storage";
var storage = new Storage();
export default {
  name: "transaction-history-card",
  props: {
    data: {
      required: false
    }
  },
  data() {
    return {};
  },
  methods: {
    processAmount(amount) {
      const parsedAmount = Number(amount);
      const country = storage.getSavedState("currentUser").country;
      const isFrancophoneCountry = country === "CI" || country === "SN";
      return parsedAmount.toLocaleString(isFrancophoneCountry ? "fr-CI" : "en-US", {
        style: "currency",
        currency: isFrancophoneCountry ? "CFA" : "GHS"
      });
    }
  }
};